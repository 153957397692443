/* eslint-disable quotes */
import bp from './breakpoints';

const MQ = {
  mobile: `${bp.mobile}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const BZ = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

export const FONTS = {
  // heading: `'Raleway', sans-serif`,
  // body: `'Montserrat', sans-serif`,
  heading: `'Source Sans Pro', sans-serif`,
  body: `'Source Sans Pro', sans-serif`,
};

export const COLORS = {
  dark: '#222',
  light: '#f5fbff',
  purple: '#9135AD',
  themeBlue: '#007AFF',
  themeOrange: '#FF9500',
};

export const Z = {
  modalBackground: `z-index: 100000;`,
  modal: `z-index: 200000;`,
};

export const DURATION = 500;
