import React, { useState, useContext, useRef, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import throttle from 'lodash/throttle';

import '../style/tinyslider.css';
import '../style/tailwind.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header';
import Global from '../style/global';
import ModalContext from '../store/modalContext';
import Modals from '../modals';
import { layoutTypes } from '../types/propTypes';

const contactInfoQuery = graphql`
  {
    contactInfo: datoCmsContactInfo {
      address
      facebookUrl
      instagramUrl
      whatsappNumber
      email
      phone
    }
  }
`;
const {
  Provider: ContactProvider,
  Consumer: ContactConsumer,
} = React.createContext({});

export { ContactConsumer };

const Layout = ({ children, location }) => {
  const data = useStaticQuery(contactInfoQuery);
  const { open } = useContext(ModalContext);
  const [isScrolled, setIsScrolled] = useState(false);

  const navRef = useRef();
  navRef.current = isScrolled;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 70;
      if (navRef.current !== show) {
        setIsScrolled(show);
      }
    };
    document.addEventListener(
      'scroll',
      throttle(() => {
        handleScroll();
      }, 100)
    );
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function renderModal() {
    if (!open) return null;
    const ModalComponent = Modals[open];
    return <ModalComponent />;
  }
  return (
    <ContactProvider value={data.contactInfo}>
      <Global />
      <Header
        contactInfo={data.contactInfo}
        scrolled={isScrolled}
        location={location}
      />
      {children}
      {renderModal()}
    </ContactProvider>
  );
};

Layout.propTypes = layoutTypes;

export default Layout;
