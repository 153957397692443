import 'typeface-inter';
import { createGlobalStyle } from 'styled-components';
import { FONTS, COLORS } from '../consts/style';

const Global = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: ${FONTS.body};
    font-size: 18px;
    font-weight: 300;
    color: #333333;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: ${FONTS.heading};
    font-weight: bold;
  }

  *:focus {
      outline: none !important;
  }

  select {
      appearance: none;
      outline: none;
  }

  .pl-container {
    @media (min-width: 640px) {
      padding-left: calc( (100vw - 640px) / 2 );
    }

    @media (min-width: 768px) {
      padding-left: calc( (100vw - 768px) / 2 );
    }

    @media (min-width: 1024px) {
      padding-left: calc( (100vw - 1024px) / 2 );
    }

    @media (min-width: 1280px) {
      padding-left: calc( (100vw - 1280px) / 2 );
    }
  }

  .home-btn {
    &:hover {
      background: ${COLORS.themeBlue};
    }
  }

  .social-link {
    display: inline-block;

    &:hover {
      svg {
        fill: #454545;
      }
    }
    svg {
      fill: ${COLORS.themeOrange};
    }
  }

  .social-link-invert {
    display: inline-block;

    &:hover {
      svg {
        fill: white;
      }
    }
    svg {
      fill: ${COLORS.dark};
    }
  }

  .withUnderline {
    &:hover {
      &:after {
        display: none;
      }
    }
    &:after {
      content: '';
      display: block;
      width: 80%;
      margin: auto;
      background: ${COLORS.themeOrange};
      height: 1px;
    }
  }

  .richText p {
    margin-top: 1em;
  }

`;

export default Global;
