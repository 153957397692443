import React, { useContext } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';

import ModalContext from '../store/modalContext';
import { BZ, Z } from '../consts/style';
import { fadeInUp } from '../style/animations';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  ${Z.modalBackground};
`;

export const ModalInner = styled.div`
  background: white;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  height: 25rem;
  width: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeInUp} 0.3s;
  ${BZ};
  ${Z.modal};
`;

export default function Basic() {
  const { closeModal } = useContext(ModalContext);
  return (
    <ModalWrapper>
      <OutsideClickHandler onOutsideClick={closeModal}>
        <ModalInner>hey</ModalInner>
      </OutsideClickHandler>
    </ModalWrapper>
  );
}
