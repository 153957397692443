import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import styled from 'styled-components';
import FbIcon from '../svgs/socials-fill/facebook.inline.svg';
import InstaIcon from '../svgs/socials-fill/instagram.inline.svg';
import WaIcon from '../svgs/socials-fill/whatsapp.inline.svg';
import { headerTypes } from '../types/propTypes';

const TopNav = styled.nav`
  @media (max-width: 640px) {
    img {
      height: 40px;
    }
  }
`;

const headerQuery = graphql`
  {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default function Header({ location, scrolled, contactInfo }) {
  const data = useStaticQuery(headerQuery);
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const closeDropdown = () => {
    setCollapsed(true);
  };
  /**
   * Oftentimes we'll have different UI state
   * based on the router location.  Do it here.
   */
  // useEffect(() => console.log(location), [location]);
  const commonClass =
    'transition-all duration-300 ease-in-out fixed inset-x-0 flex items-center justify-between flex-wrap z-50 font-normal text-current bg-white';
  const unscrolledClass = commonClass + ' ' + 'px-6 py-4';
  const scrolledClass = commonClass + ' ' + 'px-6 py-4 shadow-lg';

  const menuClass = 'w-full block flex-grow md:flex md:items-center md:w-auto';
  const collapsedMenu = 'hidden ' + menuClass;

  const menuTextClass = 'hover:text-orange-theme md:mr-4 my-2 uppercase';

  return (
    <TopNav
      className={scrolled || !collapsed ? scrolledClass : unscrolledClass}
    >
      <Link to="/" className="flex items-center flex-shrink-0 text-white mr-6">
        <Img id="navLogo" fixed={data.logo.childImageSharp.fixed} />
      </Link>
      <div className="block md:hidden">
        <button
          onClick={toggleCollapsed}
          className="flex items-center px-3 py-2 border border-orange-theme rounded"
        >
          <svg
            className="fill-orange h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div className={collapsed ? collapsedMenu : menuClass}>
        <div className="md:flex-grow text-right flex items-center justify-end flex-col md:flex-row">
          <Link
            to="/"
            className="md:mr-5 my-2 bg-orange-theme rounded-full p-2 home-btn"
            onClick={closeDropdown}
          >
            <svg
              className="fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
            >
              <path d="M13 20v-5h-2v5a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-7.59l-.3.3a1 1 0 1 1-1.4-1.42l9-9a1 1 0 0 1 1.4 0l9 9a1 1 0 0 1-1.4 1.42l-.3-.3V20a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2zm5 0v-9.59l-6-6-6 6V20h3v-5c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v5h3z" />
            </svg>
          </Link>
          <Link
            to="/services"
            className={menuTextClass}
            activeClassName="text-orange-theme"
            onClick={closeDropdown}
          >
            SERVICES
          </Link>
          <Link
            to="/portfolio"
            className={menuTextClass}
            activeClassName="text-orange-theme"
            onClick={closeDropdown}
          >
            OUR PROJECTS
          </Link>
          <Link
            to="/#about-us"
            className={menuTextClass}
            activeClassName="text-orange-theme"
            onClick={closeDropdown}
          >
            ABOUT US
          </Link>
          <Link
            to="/contact"
            className={menuTextClass}
            activeClassName="text-orange-theme"
            onClick={closeDropdown}
          >
            CONTACT
          </Link>
          {/* <Link
            to="/contact"
            className="md:mr-4 px-4 py-3 leading-none border rounded-full bg-orange-theme text-white border-orange-theme hover:bg-transparent hover:text-orange-theme uppercase"
            onClick={closeDropdown}
          >
            CONTACT
          </Link> */}
          <div className="flex items-center mt-2 md:ml-1 md:mt-0">
            <a
              className="social-link mx-1"
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/${contactInfo.whatsappNumber}`}
            >
              <WaIcon className="w-8 h-8" />
            </a>
            <a
              className="social-link mx-1"
              target="_blank"
              rel="noreferrer"
              href={contactInfo.facebookUrl}
            >
              <FbIcon className="w-8 h-8" />
            </a>
            <a
              className="social-link mx-1"
              target="_blank"
              rel="noreferrer"
              href={contactInfo.instagramUrl}
            >
              <InstaIcon className="w-8 h-8" />
            </a>
          </div>
        </div>
      </div>
    </TopNav>
  );
  // return (
  //   <Wrapper>
  //     <Image fluid={data.logo.childImageSharp.fluid} />
  //   </Wrapper>
  // );
}

Header.propTypes = headerTypes;
